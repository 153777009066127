import axios, { AxiosRequestConfig } from "axios";

export const API_URL = process.env.REACT_APP_API_URL;

const getApiUrl = async () => {
  const { data: config } = await axios.get("/config.json");
  return `${config.apiUrl}`;
};

export const apiClient = async (configs: AxiosRequestConfig) => {
  return axios({
    ...configs,
    headers: {
      ...configs.headers,
      Authorization: "Bearer SOMETHING",
    },
    baseURL: API_URL ? API_URL : await getApiUrl(),
  });
};
