import { Menu } from "antd";

export const SideBar = () => {
  return (
    <div className="bg-sidebar-color relative h-full min-h-screen">
      <Menu defaultSelectedKeys={["2"]} theme="dark" mode="inline">
        <Menu.Item className="custom-menu-item" key="1">
          <div className="text-white font-normal text-base custom-menu-title">
            Home
          </div>
        </Menu.Item>
        <Menu.Item className="custom-menu-item" key="2">
          <div className="text-white font-normal text-base custom-menu-title">
            Manage Import Types
          </div>
        </Menu.Item>
        <Menu.Item className="custom-menu-item" key="3">
          <div className="text-white font-normal text-base custom-menu-title">
            Log Out
          </div>
        </Menu.Item>
      </Menu>
    </div>
  );
};
