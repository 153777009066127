import { ClientContextProvider } from "react-fetching-library";
import { Client } from "./api/Client";
import { Shell } from "./components";

const App = () => {
  return (
    <ClientContextProvider client={Client}>
      <Shell />
    </ClientContextProvider>
  );
};

export default App;
